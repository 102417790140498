.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






.search-result {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.search-result-left {
  flex: 1;
  padding-right: 20px;
}

.search-result-right {
  flex: 0 0 200px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.search-result-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.search-result-title a {
  text-decoration: none;
  color: #007bff;
}

.search-result-title a:hover {
  text-decoration: underline;
}

.search-result-snippet {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.search-result-class,
.search-result-unit,
.search-result-skill {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.search-result-class {
  color: #007bff;
}

.search-result-unit {
  color: #28a745;
}

.search-result-skill {
  color: #17a2b8;
}



/* Prevent layout shifts when loading or fetching data */
.navbar-search-container {
  display: block;
  height: 100%;
}

.search-result {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
}

.search-result-left {
  flex: 1;
  padding-right: 15px;
}

.search-result-right {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.search-result-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.search-result-title a {
  text-decoration: none;
  color: #007bff;
}

.search-result-snippet {
  font-size: 14px;
  color: #555;
}

.search-result-class,
.search-result-unit,
.search-result-skill {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.search-result-class {
  color: #007bff;
}

.search-result-unit {
  color: #28a745;
}

.search-result-skill {
  color: #17a2b8;
}

/* Style adjustments for navbar-specific cases */
.navbar-search-container .search-result {
  min-height: 60px; /* Prevent layout jump */
}

.loading-spinner {
  text-align: center;
  margin-top: 10px;
}
